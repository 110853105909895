/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: (resolve) => require(['@/views/Home'], resolve),
	},
	{
		path: '/upload',
		name: 'upload',
		component: (resolve) => require(['@/views/upload'], resolve),
	},
	{
		path: '/aiGeneration',
		name: 'aiGeneration',
		component: (resolve) => require(['@/views/aiGeneration'], resolve),
	},
]

const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router
