/** @format */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import 'vant/lib/index.less' // 引入vantui组件全部样式
import {
	Toast,
	Swipe,
	SwipeItem,
	Lazyload,
	Uploader,
	Popup,
	Image as VanImage,
	Loading,
} from 'vant'

const Base64 = require('js-base64').Base64

Vue.use(Loading)
Vue.use(Popup)
Vue.use(VanImage)
Vue.use(Uploader)
Vue.use(Lazyload)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Toast)

Vue.use(Lazyload, {
	lazyComponent: true,
})

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false
Vue.prototype.router = router
new Vue({
	router,
	Base64,
	render: (h) => h(App),
}).$mount('#app')
